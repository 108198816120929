
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import BtnCheckGroup from "@/components/scope/btn-check-group.vue";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";

const customer = namespace("customer");
const user = namespace("user");
const app = namespace("app");
const base = namespace("base");
const marketing = namespace("marketing");
const setting = namespace("setting");

@Component({
  components: { BtnCheckGroup }
})
export default class ServiceEdit extends Vue {
  @app.Mutation setPBPX;
  @customer.Action getCustomerList;
  @customer.Action getCustomerDetail;
  @marketing.Action queryCardTemplateList;
  @marketing.Action queryCardTemplateDetail;
  @marketing.Action validateAddCard;
  @marketing.Action createCard;
  @marketing.Action queryCustomerCard;
  @base.Action getDictionaryList;
  @user.State userData;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @setting.Action getPatternPaymentList;
  dayjs = dayjs;
  splitThousands = splitThousands;
  showPaymentMethod: boolean = false;
  showOpenCardValidate: boolean = false;
  openCardValidateMsg: string = "";
  paymentMethod: string = "";
  get breadData() {
    return [
      { name: t("marketing.card-manage"), path: "/marketing/card" },
      { name: this.isAdd ? t("service.open-card") : t("service.open-card") }
    ];
  }
  get isAdd() {
    return this.$route.name === "cardOpen";
  }
  typeOptionsData: any = [
    {
      label: t("v210906.package-card"),
      value: "1"
    },
    {
      label: t("v210906.stored-value-card"),
      value: "2"
    }
  ];
  payWayList: any[] = [];
  get orderType() {
    return this.$route.query.type;
  }
  get urlCustomerCode() {
    return this.$route.query.customerCode;
  }
  serviceList: any[] = [];
  emptyToLine = emptyToLine;
  showSelectCustomer: boolean = false;
  orderFormData: any = {
    cardType: "1", // 卡类型1：套餐卡 2：储值卡
    cardTemplateCode: "", // 套餐模板code
    activatedDate: dayjs().format("YYYYMMDD"), // 开卡日期
    customerCode: "",
    mobile: "",
    firstName: ""
  };
  cardType: 1;
  cardTemplateName: "";
  cardTemplateList: any = [];
  serviceItemList: any[] = [];
  cardTemplateInfo: any = {};
  get orderId() {
    return this.$route.params.id;
  }
  showPaymentMethodModal() {
    if (this.checkAddData()) {
      this.showPaymentMethod = true;
    }
  }
  created() {
    this.setPBPX(52);
    this.init();
  }
  get getQuery() {
    return this.$route.query.vehicleCode;
  }
  async init() {
    if (this.getQuery) {
      console.log("[ 123 ]", 123);
      this.orderFormData.cardType = "2";
      this.changeCardType(2);
      this.setCustomerData(this.$route.query.vehicleCode, this.$route.query.customerCode);
    } else {
      // this.getCardTemplateList();
    }
    this.getPatternPaymentList({
      status: "1"
    }).then(data => {
      this.payWayList =
        data.data.filter(item => {
          return item.payMethodCode !== "6";
        }) || [];
    });
    if (this.urlCustomerCode) {
      this.setCustomerData("", this.urlCustomerCode);
    }
  }
  getCardTemplateList() {
    this.queryCardTemplateList({
      pageNum: 1,
      pageSize: 999,
      status: 1,
      cardType: this.cardType,
      cardTemplateName: this.cardTemplateName
    }).then(data => {
      this.cardTemplateList = data.data.list;
    });
  }
  groupIndex: number = 1;

  queryCardTemplateHandle(e){
    this.cardTemplateName = e;
   this.getCardTemplateList();
  }

  handleCardTemplateChange(val) {
    this.groupIndex = 1;
    this.queryCardTemplateDetail({
      cardTemplateCode: val
    }).then(data => {
      data = data.data || { serviceList: [] };
      console.log("[ data ]", data);
      if (this.orderFormData.cardType === "1") {
        data.serviceList &&
          data.serviceList.forEach(serviceItem => {
            if (serviceItem.partsList && serviceItem.partsList.length > 0) {
              serviceItem.isGroup = true;
              serviceItem.groupServiceIndex = this.groupIndex;
              serviceItem.partsList.forEach(partsItem => {
                data.partsList.push(
                  Object.assign(partsItem, {
                    isGroup: true,
                    groupServiceIndex: this.groupIndex
                  })
                );
              });
              this.groupIndex++;
            }
          });
      }
      if (data.validPeriod === -1) {
        data.dateExpiry = t("marketing.permanent");
      } else {
        data.dateExpiry = dayjs().add(data.validPeriod, "d").format("YYYY-MM-DD HH:mm:ss");
      }
      this.cardTemplateInfo = data;
    });
  }
  submitForm() {
    if (!this.paymentMethod) {
      this.$message.error(t("v210831.please-select-payment"));
    } else {
      this.handleValidateAddCard();
      // this.addCard();
    }
  }
  handleValidateAddCard() {
    this.validateAddCard(this.orderFormData).then(data => {
      if (data.data) {
        this.addCard();
      } else {
        this.openCardValidateMsg = data.message;
        this.showOpenCardValidate = true;
        this.showPaymentMethod = false;
      }
    });
  }
  checkAddData(): boolean {
    if (!this.orderFormData.cardTemplateCode) {
      this.$message.error(t("v210906.please-select-a-card-template-first"));
      return false;
    }
    if (!this.orderFormData.customerCode) {
      this.$message.error(t("v210831.please-select-a-client"));
      return false;
    }
    return true;
  }
  addCardIsStart: boolean = true;
  addCard() {
    if (this.addCardIsStart) {
      this.addCardIsStart = false;
      this.btnStartLoading();
      Object.assign(this.orderFormData, {
        paymentList: [
          {
            paidMethod: this.paymentMethod,
            paidAmount: this.cardTemplateInfo.cardAmount
          }
        ]
      });
      this.createCard(this.orderFormData)
        .then(data => {
          this.$message.success(t("v210906.card-opening-succeeded"));
          this.queryCustomerCard({
            customerCode: this.orderFormData.customerCode,
            partsList: undefined,
            serviceList: undefined
          });
          this.$router.push(`/marketing/card-view/${data.data.cardCode}`);
        })
        .finally(() => {
          this.btnStopLoading();
          this.addCardIsStart = true;
        });
    }
  }

  deleteItem(key, index) {
    console.log(key, index);
    this.orderFormData[key].splice(index, 1);
  }

  linkCreateCustomer() {
    let { href } = this.$router.resolve({
      path: `/customer/customer-add`
    });
    window.open(href, "_blank");
  }

  seletedCustomer: string = "";
  customerList: any[] = [];
  handleShowSelCustomer() {
    this.showSelectCustomer = true;
    this.$nextTick(() => {
      (this.$refs["select-customer"] as any).focus();
    });
  }
  handleSearchCustomer(val) {
    if (val) {
      this.getCustomerList({
        pageSize: 20,
        pageNum: 1,
        searchKey: val
      }).then(data => {
        if (!data.data.list) {
          this.customerList = [];
        } else {
          this.customerList = data.data.list.map(item => {
            item.label = `${item.storeName } ${ "/" + item.plateCode}${item.customerName ? "/" + item.customerName : ""} ${
              item.mobile ? "/" + item.mobile : ""
            }`;
            return item;
          });
        }
      });
    } else {
      this.customerList = [];
    }
  }
  handleChooseCustomer(index) {
    // const customer = this.customerList.find(item => {
    //   return val === item.customerCode;
    // });
    const customer = this.customerList[index];
    console.log(customer)
    this.setCustomerData(customer.vehicleCode, customer.customerCode);
  }
  setCustomerData(vehicleCode, customerCode) {
    this.orderFormData.customerCode = customerCode;
    this.getCustomerDetail({
      customerCode: customerCode,
      vehicleCode: vehicleCode
    }).then(data => {
      const customerData = data.data;
      if (!customerData.customerName || !customerData.mobile) {
        this.$confirm(t("v210906.you-need-the-customers-name-and-mobile-phone"), t("v210831.tips"), {
          confirmButtonText: t("v210831.determine"),
          cancelButtonText: t("base.cancel"),
          type: "warning"
        })
          .then(() => {
            let { href } = this.$router.resolve({
              path: `/customer/customer-edit/${customerCode}?vehicleCode=${customerData.vehicleList[0].vehicleCode}`
            });
            window.open(href, "_blank");
          })
          .catch(() => {});
      } else {
        this.orderFormData.firstName = customerData.customerName;
        this.orderFormData.mobile = customerData.mobile;
      }
    });
  }
  changeCardType(cardType) {
    // console.log("[ cardType ]", cardType);
    this.orderFormData.cardTemplateCode = undefined;
    this.cardType = cardType;
    this.orderFormData.cardType = this.cardType;
    this.cardTemplateName = "";
    this.cardTemplateList = []
    this.getCardTemplateList();
  }
  // ////
}
